import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import { reduxForm, change, Field } from 'redux-form';
import * as validators from 'Utils/validators';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextEditor from 'SharedComponent/TextEditor';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import AttachmentFiles, { AttachFilesButton, Files } from 'SharedComponent/AttachmentFiles';

import './AddEmailTemplate.scss';

class AddEmailTemplate extends Component {
  static propTypes = {
    showDetails: PropTypes.bool,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    showDetails: false,
    onDismiss: new Function(),
  };

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  closeModal() {
    this.props.reset();
    this.props.onDismiss();
  }

  submit(template) {
    const _templateFormData = new FormData();
    _templateFormData.append('name', template.name);
    _templateFormData.append('content', template.content);

    if (template.files) {
      for (let i = 0; i < template.files.length; i++) {
        if (template.files[i]) {
          _templateFormData.append(`attachments[${i}]`, template.files[i]);
        }
      }
    }

    if (this.props.initialValues) {
      this.props.onUpdate(_templateFormData, this.props.initialValues.id);
      gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).button);
    } else {
      this.props.onSave(_templateFormData);
      gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
    }
    this.props.reset();
    this.closeModal();
  }

  onCloseDialogBox() {
    this.props.initialValues
      ? gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).icon);
    this.closeModal();
  }

  trackEvent = (label, action = appEvents.clicked, element = 'button') => {
    gaEvent(appCategories.deal_emails, appActions(label, action)[element]);
  };

  removeFile = id => {
    // get files
    let files = [...this.props.formValues.files];
    if (files) {
      // change form value
      files.splice(id, 1);
      this.props.dispatch(change('add-email-template', `files`, files));
    }
  };

  onClickCancelBtn() {
    this.props.initialValues
      ? gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).button);
    this.closeModal();
  }

  render() {
    const { handleSubmit, initialValues, change, submitting, pristine } = this.props;
    console.log('intialValues', initialValues);
    if (!this.props.showModal) return null;
    return (
      <div className='AddEmailTemplate'>
        <DialogBox isVisible onClose={() => this.onCloseDialogBox()} className='' title={initialValues ? 'Update Email Template' : 'NewEmail Template'}>
          <div className='header-txt ml-4'></div>
          <form className='pl-3 pr-3 pt-3' onSubmit={handleSubmit(this.submit)}>
            <div className='display-flex-center'>
              <Field label='Template Title' name='name' component={TextField} type='text' placeholder='Type template title' required validate={[validators.required]} />
            </div>
            <div className='mt-4'>
              <Field
                name='content'
                filesOutputName='files'
                mentionsOutputName='mentions'
                component={TextEditor}
                toolbarPosition='top'
                formName='add-email-template'
                colorPicker={true}
              />
            </div>
            <Files
              removeFile={this.removeFile}
              fileUrls={initialValues ? initialValues.attachment_urls : undefined}
              files={this.props.formValues ? this.props.formValues.files : undefined}
            />
            <div className='display-flex-between m-3'>
              <div className='display-flex-start mr-3' style={{ maxWidth: '200px' }}>
                <Field name='files' component={AttachFilesButton} onClick={event => this.trackEvent('EMAIL_ATTACH_FILE', undefined, 'item')} />
              </div>
              <div className='display-flex-end'>
                <div className='m-3'>
                  <SubmitButton
                    label='Cancel'
                    icon='icon-close'
                    style={{
                      width: '150px',
                      color: '#53627c',
                      backgroundColor: '#fff',
                      border: '1px solid #e1e4e8',
                    }}
                    onClick={() => this.onClickCancelBtn()}
                  />
                </div>
                <div>
                  <Button disabled={submitting || pristine} type='submit' className='submit-btn-p'>
                    <i className='icon-check mr-2' />
                    {initialValues ? 'UPDATE' : 'SAVE'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form['add-email-template'] ? state.form['add-email-template'].values : undefined,
  };
}

AddEmailTemplate = reduxForm({
  form: 'add-email-template',
  enableReinitialize: true,
})(AddEmailTemplate);
AddEmailTemplate = connect(state => ({
  initialValues: state.settings.emailTemplates.editing,
}))(AddEmailTemplate);
export default connect(mapStateToProps)(AddEmailTemplate);
